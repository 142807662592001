import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Index',
    redirect: "/user"
  },
  {
    path: '/registerUser',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/register.vue'),
    name: 'registerUser',
  },
  {
    path: '/registerDriver',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/register.vue'),
    name: 'registerDriver',
  },
  {
    path: '/loginUser',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/login.vue'),
    name: 'loginUser',
  },
  {
    path: '/loginDriver',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/login.vue'),
    name: 'loginDriver',
  },
  {
    path: '/resetPass',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/resetPass.vue'),
    name: 'resetPass',
  },
  {
    path: '/setting',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/setting.vue'),
    name: 'setting',
  },
  {
    path: '/wallet',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/wallet.vue'),
    name: 'wallet',
  },
  {
    path: '/infoShow',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/infoShow.vue'),
    name: 'infoShow',
  },
  {
    path: '/aboutUs',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/aboutUs.vue'),
    name: 'aboutUs',
  },
  {
    path: '/contactUs',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/contactUs.vue'),
    name: 'contactUs',
  },
  {
    path: '/howToUse',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/howToUse.vue'),
    name: 'howToUse',
  },
  {
    path: '/inforSetting',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/inforSetting.vue'),
    name: 'inforSetting',
  },
  {
    path: '/salaryWater',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/salaryWater.vue'),
    name: 'salaryWater',
  },
  {
    path: '/deliverDetail',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/user/deliverDetail.vue'),
    name: 'deliverDetail',
  },
  {
    path: '/user',
    name: 'User',
    component: Home,
    redirect: "/mainPage",
    children: [{
        path: '/mainPage',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/mainPage.vue'),
        name: 'mainPage',
      },
      {
        path: '/chooseDeliveryman',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/chooseDeliveryman.vue'),
        name: 'chooseDeliveryman',
      },
      {
        path: '/moreOrder',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/moreOrder.vue'),
        name: 'moreOrder',
      },
      {
        path: '/deliveryInfo',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/deliveryInfo.vue'),
        name: 'deliveryInfo',
      },
      {
        path: '/packageInfo',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/packageInfo.vue'),
        name: 'packageInfo',
      },
      {
        path: '/tip',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/tip.vue'),
        name: 'tip',
      },
      {
        path: '/driverList',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/driverList.vue'),
        name: 'driverList',
      },
      {
        path: '/myOrder',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/myOrder.vue'),
        name: 'myOrder',
      },
      {
        path: '/deliverSummary',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/deliverSummary.vue'),
        name: 'deliverSummary',
      },
      {
        path: '/orderDetail',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/user/orderDetail.vue'),
        name: 'orderDetail',
      }
      
    ]
  },
  {
    path: '/deliverer',
    name: 'Deliverer',
    component: Home,
    redirect: "/mainTaskPage",
    children: [{
        path: '/mainTaskPage',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/mainTaskPage.vue'),
        name: 'mainTaskPage',
      },
      {
        path: '/verifyStepOne',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/verifyStepOne.vue'),
        name: 'verifyStepOne',
      },
      {
        path: '/verifyStepTwo',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/verifyStepTwo.vue'),
        name: 'verifyStepTwo',
      },
      {
        path: '/verifyStepThree',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/verifyStepThree.vue'),
        name: 'verifyStepThree',
      },
      {
        path: '/verifyStatus',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/verifyStatus.vue'),
        name: 'verifyStatus',
      },
      {
        path: '/completedTask',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/completedTask.vue'),
        name: 'completedTask',
      },
      {
        path: '/toAcceptTask',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/toAcceptTask.vue'),
        name: 'toAcceptTask',
      },
      {
        path: '/toDeliverTask',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/toDeliverTask.vue'),
        name: 'toDeliverTask',
      },
      {
        path: '/optimizedRoute',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/optimizedRoute.vue'),
        name: 'optimizedRoute',
      },
      {
        path: '/dayDeliveryTask',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/dayDeliveryTask.vue'),
        name: 'dayDeliveryTask',
      },
      {
        path: '/personalPage',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/personalPage.vue'),
        name: 'personalPage',
      },
      {
        path: '/dayOptimizedRoute',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/deliverer/dayOptimizedRoute.vue'),
        name: 'dayOptimizedRoute',
      },
    ]
  },
  {
    path: '/multiDelivery',
    name: 'MultiDelivery',
    component: Home,
    redirect: "/submitMultiStopReq",
    children: [
      {
        path: '/submitMultiStopReq',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/submitMultiStopReq.vue'),
        name: 'submitMultiStopReq',
      },
      {
        path: '/multiOptimizedRoute',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/multiOptimizedRoute.vue'),
        name: 'multiOptimizedRoute',
      },{
        path: '/previewOptimizedRoute',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/previewOptimizedRoute.vue'),
        name: 'previewOptimizedRoute',
      },{
        path: '/pcExcelImport',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/pcExcelImport.vue'),
        name: 'pcExcelImport',
      },{
        path: '/myMultiOrder',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/myMultiOrder.vue'),
        name: 'myMultiOrder',
      },{
        path: '/myMultiDetail',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/myMultiDetail.vue'),
        name: 'myMultiDetail',
      },{
        path: '/allocateCargo',
        component: () =>
          import( /* webpackChunkName: "about" */ '../views/multiDelivery/allocateCargo.vue'),
        name: 'allocateCargo',
      },]
  }
]

const router = new VueRouter({
  base: "",
  mode: "history",
  routes
})

export default router