<template>
  <div class="MainContainer">
    <router-view/>
  </div>
</template>

<script>
  // @ is an alias to /src
  export default {
    name: 'Home',
    components: {},
    data() {
      return {}
    },
    mounted() {},
    methods: {}
  }
</script>
<style>
  .MainContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
</style>