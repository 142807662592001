import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import axios from './assets/axios'
//import "./assets/css/temp.css"
import "./assets/css/normalize.css"
import "./assets/css/global.css"
import "./assets/css/common.css"
import { Loader } from "@googlemaps/js-api-loader"
const loader = new Loader({
  apiKey: "AIzaSyDR0LyFODkJ8CS3B8bGO_YoCzK3C279O90",
  version: "weekly",
  libraries: ["places"]
});
loader.load().then(() => {
  class Popup extends google.maps.OverlayView {
    constructor(position, content, clickFn) {
      super();
      this.position = position;
      content.classList.add("popup-bubble");
      // This zero-height div is positioned at the bottom of the bubble.
      const bubbleAnchor = document.createElement("div");
      bubbleAnchor.classList.add("popup-bubble-anchor");
      bubbleAnchor.appendChild(content);
      content.addEventListener("click", clickFn)
      // This zero-height div is positioned at the bottom of the tip.
      this.containerDiv = document.createElement("div");
      this.containerDiv.classList.add("popup-container");
      this.containerDiv.appendChild(bubbleAnchor);
      // Optionally stop clicks, etc., from bubbling up to the map.
      Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
    }
    /** Called when the popup is added to the map. */
    onAdd() {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    }
    /** Called when the popup is removed from the map. */
    onRemove() {
      if(this.containerDiv.parentElement) {
        this.containerDiv.parentElement.removeChild(this.containerDiv);
      }
    }
    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this.position
      );
      // Hide the popup when it is far out of view.
      const display =
        Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
        "block" :
        "none";
      if(display === "block") {
        this.containerDiv.style.left = divPosition.x + "px";
        this.containerDiv.style.top = divPosition.y + "px";
      }
      if(this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    }
  }
  Vue.prototype.Popup = Popup;
})
Vue.config.productionTip = false
Vue.prototype.loader = loader
Vue.prototype.$http = axios
Vue.prototype.isPhone = isMobileDevice();
Vue.prototype.formatdate = formatdate;
Vue.prototype.getDateOption = getDateOption;
Vue.prototype.getLocation = getLocation;
Vue.prototype.getDistance = getDistance;
Vue.prototype.getPriceRule = getPriceRule
Vue.prototype.orderStatus = {
  "-1": "ServerInvalid",
  0: "Unpaid",
  1: "Matching to drivers",
  2: "Paired to driver",
  3: "To be picked up",
  4: "PickedUp",
  5: "on the way to destination",
  6: "Delivered",
  7: "Problematic",
  8: "Processing",
  9: "Abnormal completed,Cause of user",
  10: "Closed",
  11: "Timeout Closed",
  12: "Abnormal completed,Cause of courier",
  13: "Abnormal completed,Cause of timeout",
  14: "Failed to pick up",
  15: "Failed to delivery"
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

function isMobileDevice() {
  var pattern = new RegExp('Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini');
  return pattern.test(navigator.userAgent);
}

function formatdate(time) {
  let optionValue = time;
  let date = new Date(optionValue);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1);
  let day = (date.getDate());
  let days = date.getDay();
  let minutes = date.getMinutes();
  let hour = date.getHours();
  let second = date.getSeconds()
  return month + "/" + day + "/" + year + " " + hour + ":" + minutes + ":" + second;
}
var sizeTemp = document.documentElement.clientWidth > document.documentElement.clientHeight ? document.documentElement.clientHeight : document.documentElement.clientWidth
document.documentElement.style.fontSize = sizeTemp / 7.5 + 'px';

function getDateOption() {
  let optionArr = [];
  for(let i = 0; i < 3; i++) {
    let optionValue = (new Date()).getTime() + 86400000 * i
    let date = new Date(optionValue);
    let year = date.getFullYear();
    let month = (date.getMonth() + 1);
    let day = (date.getDate());
    optionArr.push({
      month,
      day,
      year,
      selected: false
    })
  }
  return optionArr
}

function getLocation(callback) {
  if(navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        callback(pos)
      },
      () => {
        callback({
          errorInfo: "Error: The Geolocation service failed."
        })
      }, {
        timeout: 5000
      }
    );
  } else {
    callback({
      errorInfo: "Error: Your browser doesn't support geolocation."
    })
  }
}
// 经纬度转换成三角函数中度分表形式。
function rad(d) {
  return d * Math.PI / 180.0;
}
// 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
function getDistance(position1, position2) {
  let radLat1 = rad(position1.lat);
  let radLat2 = rad(position2.lat);
  let a = radLat1 - radLat2;
  let b = rad(position1.lng) - rad(position2.lng);
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137; // EARTH_RADIUS;
  s = Math.round(s * 10000) / 10000; //输出为公里
  return(s * 0.6213712).toFixed(3) //返回mile
}
function getPriceRule() {
  let resData = JSON.parse(`{"tax":0.09,"volume":{"list":[8,27,0],"rule":{"8":0,"27":200}},"weight":{"list":[30,50,70],"rule":{"30":0,"50":200,"70":500}}}`)
  let weight = resData.weight;
  let size = resData.volume;
  let weightInfoArr = [{
      title: `<${weight.list[0]}lb`,
      rule: weight.rule[weight.list[0]]
    },
    {
      title: `${weight.list[0]}-${weight.list[1]}lb`,
      rule: weight.rule[weight.list[1]]
    },
    {
      title: `${weight.list[1]}-${weight.list[2]}lb`,
      rule: weight.rule[weight.list[2]]
    }
  ];
  let sizeInfoArr = [{
      title: `<${size.list[0]}sqft`,
      rule: size.rule[size.list[0]]
    },
    {
      title: `${size.list[0]}-${size.list[1]}sqft`,
      rule: size.rule[size.list[1]]
    }
  ];
  let tax = resData.tax;
  localStorage.setItem("priceRule", JSON.stringify({
    weightInfoArr,
    sizeInfoArr,
    tax
  }))
  return;
  this.$http({
    url: "/api/order/price",
    method: "GET",
    params: {}
  }).then(res => {
    this.$message.closeAll();
    if(res.data.code) {
      this.$message({
        message: res.data.message,
        type: 'error'
      });
    } else {
      let weight = res.data.data.weight;
      let size = res.data.data.volume;
      let weightInfoArr = [{
          title: `<${weight.list[0]}lb`,
          rule: weight.rule[weight.list[0]]
        },
        {
          title: `${weight.list[0]}-${weight.list[2]}lb`,
          rule: weight.rule[weight.list[1]]
        },
        {
          title: `>${weight.list[2]}lb`,
          rule: weight.rule[weight.list[2]]
        }
      ];
      let sizeInfoArr = [{
          title: `<${size.list[1]}sqft`,
          rule: size.rule[size.list[0]]
        },
        {
          title: `>${size.list[1]}sqft`,
          rule: size.rule[size.list[1]]
        }
      ];
      let tax = res.data.data.tax;
      localStorage.setItem("priceRule", JSON.stringify({
        weightInfoArr,
        sizeInfoArr,
        tax
      }))
    }
  })
}